import React from 'react';
import { useLanguage } from './LanguageContext';
import { ButtonGroup, Button } from '@mui/material';

const LanguageSwitcher = () => {
  const { language, switchLanguage } = useLanguage();

  return (
    <ButtonGroup variant="outlined" aria-label="language switcher">
      <Button
        onClick={() => switchLanguage('en')}
        variant={language === 'en' ? 'contained' : 'outlined'}
      >
        EN
      </Button>
      <Button
        onClick={() => switchLanguage('de')}
        variant={language === 'de' ? 'contained' : 'outlined'}
      >
        DE
      </Button>
    </ButtonGroup>
  );
};

export default LanguageSwitcher;
