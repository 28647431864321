import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ThirdPartyDomainsTable = ({ domains, title }) => {
  return (
    <>
   
      <TableContainer component={Paper} sx={{ marginTop: 4, marginBottom: 4 }}>
        <Table aria-label="third-party domains table">
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domains.map((domain, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {domain}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};


export default ThirdPartyDomainsTable;
