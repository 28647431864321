import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const CookiesTable = ({ cookies, title }) => {
  return (
    <>

    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Domain</TableCell>
            <TableCell align="right">Expires Days</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { cookies.map((cookie) => (
            <TableRow
              key={cookie.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {cookie.name}
              </TableCell>
              <TableCell align="right">{cookie.domain}</TableCell>
              <TableCell align="right">{cookie.expires_days}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
};

export default CookiesTable;
