import React, { useState } from 'react';
import URLInputForm from './URLInputForm';
import CookiesTable from './CookiesTable';
import RequestTable from './RequestTable';
import ConsentManagerComponent from './ConsentManagerComponent';
import ScreenShots from './ScreenShots';
import MarkdownDisplay from './DisplayAnalysis';
import { Container, CircularProgress } from '@mui/material';
import { Grid, Typography, Paper, Box } from '@mui/material';
import { useLanguage } from './LanguageContext';

import LanguageSwitcher from './LanguageSwitcher';



const App = () => {
  const [analysisResult, setAnalysisResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const { language } = useLanguage(); 

  const fetchAnalysisResult = async (url, language) => {
    setLoading(true);

    const response = await fetch('https://crawl.ataraxie.de/consentcrawl?url=' + encodeURIComponent(url) + "&l=" + language, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Server responded with status code ${response.status}`);
    }

    let data;
    try {
      data = await response.json();
    } catch (err) {
      throw new Error('Failed to parse JSON response');
    }

    setLoading(false);
    return data;
  };


  const handleURLSubmit = async (url, language) => {
    setAnalysisResult(null);
    const result = await fetchAnalysisResult(url, language); // Implement this function as previously described
    setAnalysisResult(result.results[0]);
  };

  return (
 

      <Container component="main" maxWidth="lg">
        <Box mt={4} />
        <Typography variant="h2" align="center">Consent Analysis App</Typography>


        <Typography variant="subtitle1" align="center">
          {language === 'en' ?
            "This app analyzes the consent management of a given URL. It fetches data about cookies and requests to third party domains before and after consent. The results are displayed in tables for easy comparison. The results also include screenshots of the website before and after consent, as well as an analysis of the consent management."
            :
            "Diese App analysiert das Einverständnismnagement einer gegebenen URL. Sie holt Daten über Cookies und Anfragen an Drittanbieter Request vor und nach der Zustimmung. Die Ergebnisse werden in Tabellen zur einfachen Vergleichbarkeit angezeigt. Die Ergebnisse enthalten auch Screenshots der Website vor und nach der Zustimmung, sowie eine Analyse des Einverständnismanagements."}
        </Typography>

        <Box mt={4} />

        <LanguageSwitcher />
        <URLInputForm onSubmit={handleURLSubmit} />

        {loading ? (
          <CircularProgress sx={{ display: 'flex', justifyContent: 'center', margin: 'auto' }} /> // Render the loading bar when loading is true
        ) : analysisResult ? (
          <>
            <h2>Results for {analysisResult.domain_name}  </h2>

            <ConsentManagerComponent consentManager={analysisResult.consent_manager} />

            <ScreenShots screenShotFiles={analysisResult.screenshot_files} />


            <Paper elevation={3} sx={{ p: 2, mt: 2, margin: 'auto', flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center">Cookies</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" align="center">Before Consent</Typography>
                  <CookiesTable cookies={analysisResult.cookies_no_consent} title="Cookies before Consent" />

                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" align="center">After Consent</Typography>
                  <CookiesTable cookies={analysisResult.cookies_all} title="All Cookies" />

                </Grid>
              </Grid>
            </Paper>
            <Box mt={4} />
            <Paper elevation={3} sx={{ p: 2, mt: 2, margin: 'auto', flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center">Requests to third party domains</Typography>

                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" align="center">Before Consent</Typography>
                  <RequestTable domains={analysisResult.third_party_domains_no_consent} title="Third Party Domains before Consent" />

                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" align="center">After Consent</Typography>

                  <RequestTable domains={analysisResult.third_party_domains_all} title="All Third Party Domains" />

                </Grid>
              </Grid>
            </Paper>
            <Box mt={4} />

            <Paper elevation={3} sx={{ p: 2, mt: 2, margin: 'auto', flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" align="center">Requests to Google</Typography>

                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" align="center">Before Consent</Typography>
                  <RequestTable domains={analysisResult.google_requests_no_consent} title="Third Party Domains before Consent" />

                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" align="center">After Consent</Typography>

                  <RequestTable domains={analysisResult.google_requests} title="All Third Party Domains" />

                </Grid>
              </Grid>
            </Paper>
            <Box mt={4} />
            <MarkdownDisplay content={analysisResult.analyse.choices[0].message.content} />
          </>
        ) : null}
      </Container>
  )
};

export default App;
