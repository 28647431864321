import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const Screenshots = (props) => {
  // Ensure screenShotFiles is always treated as an array
  const screenShotFiles = Array.isArray(props.screenShotFiles) ? props.screenShotFiles : [];

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchScreenshotFiles = async () => {
      const urls = [];
      for (const fileName of screenShotFiles) {
        try {
          // use get fetch

          const response = await fetch(`https://crawl.ataraxie.de/${fileName}`,{ method: 'GET'});
          if (!response.ok) {
            throw new Error(`Server responded with status code ${response.status}`);
          }
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          urls.push(url);
        } catch (error) {
          console.error("Error fetching image:", error);
          // Optionally handle errors or set a fallback image URL here
        }
      }
      setImageUrls(urls);
    };

    if (screenShotFiles.length > 0) {
      fetchScreenshotFiles();
    }
  }, [screenShotFiles]); // Depend on screenShotFiles so it re-fetches if they change

  return (
    <Grid container spacing={2} sx={{ marginTop: 4, marginBottom: 4 }}>
      {imageUrls.map((url, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              {index === 0 ? 'Before Consent' : 'After Consent'}
            </Typography>
            <img src={url} alt={`Screenshot ${index}`} style={{ width: '100%', height: 'auto' }} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Screenshots;
