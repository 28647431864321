import React from 'react';
import { Card, CardContent, Typography, Chip, Stack, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ConsentManagerComponent = ({ consentManager }) => {
    if (!consentManager || Object.keys(consentManager).length === 0) {
        return <p>Kein Consent Manager gefunden</p>;
    }

    const { name, brand, actions, status } = consentManager;

    const StatusIcon = () => {
        if (status === 'active') return <CheckCircleOutlineIcon color="success" />;
        if (status === 'timeout') return <HighlightOffIcon color="error" />;
        return <InfoIcon />;
    };

    return (
        <Card sx={{ marginTop: 4, marginBottom: 4 }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    Consent Manager
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6">{name}</Typography>
                <Typography color="text.secondary">{brand}</Typography>
            </CardContent>
        </Card>
    );
};

export default ConsentManagerComponent;
