import React, { useState } from 'react';
import { useLanguage } from './LanguageContext';
import { TextField, Button, Box } from '@mui/material';

const URLInputForm = ({ onSubmit }) => {
  const [url, setUrl] = useState('');
  const { language } = useLanguage();

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(url, language);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Website URL"
        autoFocus
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Analyze
      </Button>
    </Box>
  );
};

export default URLInputForm;
