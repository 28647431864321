import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';



const MarkdownDisplay = ({ content }) => {


  return (
    <Paper sx={{ padding: '10px' }}>
        <Typography variant="h6" component="h2">
            Analysis
        </Typography>
        <Typography component="div">
            <ReactMarkdown>
                {content}
            </ReactMarkdown>
        </Typography>
    </Paper>
  );
};

export default MarkdownDisplay;
